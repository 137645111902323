import React, { useEffect, useState } from 'react';

import SEO from '../blocks/layout/seo';
import FormContainer from '../blocks/layout/formContainer';

import axios from 'axios';
import { Firebase } from '../config/firebase';
import { Note, Table } from '@geist-ui/core';

const Settings = () => {
    const [userSubscriptions, setUserSubscriptions] = useState([]);
    const [userInvoices, setUserInvoices] = useState([]);

    async function fetchMyAPI(user) {
        let sub = await axios.get(
            `${process.env.GATSBY_BASE_URL}/subscriptions/${user.uid}`
        );
        let inv = await axios.get(
            `${process.env.GATSBY_BASE_URL}/customerInvoices/${user.uid}`
        );
        if (sub.data.message === undefined) {
            setUserSubscriptions(sub.data);
            console.log(sub);
        }
        if (inv.data.message === undefined) {
            setUserInvoices(inv.data);
            console.log(inv);
        }
    }

    useEffect(() => {
        const unsubscribe = Firebase.auth().onAuthStateChanged(fetchMyAPI);

        // unsubscribe to the listener when unmounting
        return () => unsubscribe();
    }, []);

    return (
        <>
            <SEO title="One place for all your forms — FormWithSheet" />
            <FormContainer menuKey="5">
                <h2>User Subscriptions & invoices</h2>

                <Note label="Active Subscriptions" />
                <br />

                <Table data={userSubscriptions}>
                    <Table.Column prop="planId" label="Plan" />
                    <Table.Column
                        prop="planInterval"
                        label="Subscription Cycle"
                    />
                    <Table.Column prop="created" label="created" />
                    <Table.Column prop="customer" label="id" />
                    <Table.Column prop="status" label="status" />
                </Table>
                <br />
                <br />

                <Note label="Invoice List" />

                <br />

                <Table data={userInvoices}>
                    <Table.Column prop="id" label="Inovice Id" />
                    <Table.Column
                        prop="amount_paid"
                        label="Invoice Amount"
                        render={(value, rowData, index) => {
                            return `$${value / 100}`;
                        }}
                    />
                    <Table.Column prop="customer_email" label="Invoice Email" />
                    <Table.Column
                        prop="invoice_pdf"
                        label="View Invoice PDF"
                        render={(value, rowData, index) => {
                            return (
                                <a href={value} target="_blank">
                                    Download PDF
                                </a>
                            );
                        }}
                    />
                    <Table.Column prop="status" label="status" />
                </Table>
            </FormContainer>
        </>
    );
};

export default Settings;
